<script setup lang="ts">
import { mdiEye, mdiEyeOff } from "@mdi/js";
import { ref } from "vue";

interface Props {
  title: string;
  forceRender?: boolean;
  defaultExpanded?: boolean;
}

// props
const props = withDefaults(defineProps<Props>(), {
  title: "Filters",
  forceRender: false,
  defaultExpanded: true,
});

// states
const expand = ref(props.defaultExpanded);
</script>

<template>
  <section>
    <div class="flex items-center justify-between px-2 py-1">
      <div class="flex items-center gap-x-1">
        <h2 class="text-sm">{{ props.title }}</h2>
        <MBtn
          icon
          size="small"
          variant="text"
          color="secondary"
          @click="expand = !expand"
        >
          <MIcon
            :icon="expand ? mdiEye : mdiEyeOff"
            size="small"
          />
        </MBtn>
      </div>
      <div class="flex items-center gap-x-2">
        <slot name="actions" />
      </div>
    </div>
    <MDivider />
    <MExpandTransition>
      <div v-show="forceRender || expand">
        <slot />
      </div>
    </MExpandTransition>
  </section>
</template>
